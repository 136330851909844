// const host = 'http://localhost:9529';
// const ws = 'ws://localhost:9529';

const host = 'https://admin.lianchuangb.com/api';
const ws = 'wss://admin.lianchuangb.com/websocket';

export default {
    host,    //接口地址
    ws
}

